/* GameComponent.css */

/* Style the form elements */
.container {
    margin: 0 auto;
    width: 60%; /* Adjust the width as needed */
    margin-top: 50px;
    max-width: 600px;
    border: 1px solid #181825;
    border-radius: 5px;
    background-color: #181825;
}

label {
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
}

input[type='range'] {
    width: 100%;
}

form {
    padding: 20px;
}

button {
    width: 100%;
}


.slider-info {
    margin: 0;
    font-size: 14px;
    color: #bac2de;
  }

.go {
    color:#a6e3a1;
}

.wait {
    color:#f38ba8;
}

.finish {
    color:#a6e3a1;
}

.nofinish {
    color:#f38ba8;
}


.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}
  
.progress {
    margin-bottom: 20px;
}
.progress-bar {
    height: 60px;
}

.go .progress-bar {
    background-color: #a6e3a1;
    text-align: center;
}

.wait .progress-bar {
    background-color: #f38ba8;
    text-align: center;
}

.wait-bar {
    height: 16px;
}